<template>
  <div id="settings-password">
     <!-- Back Button -->
    <v-btn id="back-button" icon @click="$router.push('/settings/security')" class="ml-minus-2">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>

    <!-- Title -->
    <h1 class="my-5 font-weight-medium">{{ $t('views.Profile.ChangePassword') }}</h1>
    <p>{{ $t('settings.security.password.desc', { email: user.email}) }}</p>

    <!-- NEW PASSWORD -->
    <v-form
      @submit.prevent="submit"
      v-model="isFormValid"
    >
      <div class="input-container">
        <password
          v-model="password"
          :rules="rules.password"
          validate-on-blur
          ref="password"
          @input="checkValidation('password', true)"
          label="forgotPassword.submitCode.newPassword"
        ></password>
      </div>

      <div class="input-container">
        <password
          v-model="retypePassword"
          :rules="samePassword"
          validate-on-blur
          ref="retypePassword"
          @input="checkValidation('retypePassword', true)"
          label="forgotPassword.submitCode.retypePassword"
        ></password>
      </div>

      <div class="submit-container">
        <v-btn
          id="submit-btn"
          large block
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="!isFormValid || loading === 'request'"
        >
          {{ $t('settings.security.password.submit') }}
        </v-btn>
      </div>

    </v-form>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { password } from '@/utils/validators';
import Password from '@/components/Shared/InputFields/Password.vue';
import { changePassword } from '@/api/users';

export default {
  name: 'SettingsPassword',
  components: {
    Password,
  },
  data() {
    return {
      loading: null,
      password: '',
      retypePassword: '',
      rules: {
        password,
      },
      samePassword: [],
    };
  },
  computed: {
    ...mapFields('registration', [
      'isFormValid',
    ]),
    ...mapGetters('user', [
      'user',
    ]),
  },
  watch: {
    password(newVal) {
      this.samePassword = [
        (v) => v === newVal || this.$t('forgotPassword.submitCode.passwordError'),
      ];
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await changePassword({
          oldPassword: 'doesntmatter',
          newPassword: this.password,
        });
        this.$notify.success(this.$t('settings.security.password.success'));
        this.$router.push('/settings/security');
      } catch (error) {
        this.$notify.error(error);
      }
      this.loading = false;
    },
    checkValidation(refName, children = false) {
      if (!this.isFormValid) {
        if (children) {
          if (this.$refs[refName].$children[0]) {
            this.$refs[refName].$children[0].validate();
          }
        } else {
          this.$refs[refName].validate();
        }
      }
    },
  },
};
</script>

<style scoped>
.input-container {
  width: 400px;
}
.submit-container {
  width: 300px;
}
@media (max-width: 600px) {
  .input-container {
    width: 100%;
  }
  .submit-container {
    width: 100%;
  }
}
</style>
